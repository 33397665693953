import type { User } from "@supabase/gotrue-js";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { supabase } from "~/clients/supabase";
import { sleep } from "~/lib/sleep";

export type AuthState =
  | {
      signedIn: true;
      user: User;
    }
  | {
      signedIn: false;
      user: null;
    };

export const useAuthStateQuery = (
  options: { initialData?: AuthState } = {},
) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ["auth"],
    queryFn: async (): Promise<AuthState> => {
      const {
        data: { user },
        error,
      } = await supabase.auth.getUser();

      if (error || !user) {
        return {
          signedIn: false,
          user: null,
        };
      } else {
        await queryClient.invalidateQueries({ queryKey: ["user"] });
        return {
          signedIn: true,
          user: user,
        };
      }
    },
    retry: false,
    staleTime: Infinity,
    ...options,
  });
};

export const useSendOTPMutation = () => {
  return useMutation({
    mutationFn: async ({
      email,
      emailRedirectTo,
    }: {
      email: string;
      emailRedirectTo: string;
    }) => {
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: { emailRedirectTo },
      });

      if (error) {
        throw error;
      }
    },
    mutationKey: ["auth", "sendOTP"],
  });
};

export const useSignOutMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      await sleep(1000);

      const { error } = await supabase.auth.signOut();

      if (error) {
        throw error;
      }

      const authState: AuthState = {
        signedIn: false,
        user: null,
      };
      await queryClient.setQueryData(["auth"], authState);
      await queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    mutationKey: ["auth", "signOut"],
  });
};
